/* eslint-disable prettier/prettier */
/**
 * Regular Expression constants
 */

// Phone number validation RegExp constants
export const PHONE_REGEXP = {
  US_10DIGITS: /^(\d{3})(\d{3})(\d{4})$/ /* To allow only 10 digits eg: (555) 555-1234 */,
  US_11DIGITS: /^[1](\d{3})(\d{3})(\d{4})$/ /* To allow only 11 digits eg: 1 (555) 555-1234 */,
  FILTER_VALID_CHAR: /[$&,:;=?@#|'<>.^*{}[\]`%!~]/ /* To check only valid characters */,
  FILTER_NUMBERS: /\D/g /* To check only valid numbers */,
};

// Password validation RegExp constants
export const PASSWORD_REGEXP = {
    SYMBOL: '[!@#$%^&*]' /* to check password contains any symbols */,
    UPPERCASE: /\p{Lu}/u /* to check password contains any upper case letter */,
    NUMBERS: '[0-9]' /* to check password contains any numbers */,
};

// Password validation constants as pure regex for validator consumption
export const PASSWORD_REGEXP_EXPRESSIONS = {
  SYMBOL: /[!@#$%^&*]/u /* to check password contains any symbols */,
  UPPERCASE: /\p{Lu}/u /* to check password contains any upper case letter */,
  NUMBERS: /\d/u /* to check password contains any numbers */,
};


