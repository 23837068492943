import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WCUserGreeter = _resolveComponent("WCUserGreeter")!
  const _component_WCLoginMenu = _resolveComponent("WCLoginMenu")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isCustomer || _ctx.isEmployee)
      ? (_openBlock(), _createBlock(_component_WCUserGreeter, {
          key: 0,
          onLogout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('logout')))
        }))
      : (_openBlock(), _createBlock(_component_WCLoginMenu, { key: 1 }))
  ]))
}