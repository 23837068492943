
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import ModalService from '@/modules/modals/services/modal.service';
import WCItemModifierRoot from '@/modules/itemModifiers/components/WCItemModifierRoot/WCItemModifierRoot.vue';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import { defineComponent } from 'vue';
import WCRandomWeightModal from '@/modules/cart/components/WCRandomWeightModal/WCRandomWeightModal.vue';

export default defineComponent({
  components: { PlatformRenderer },
  name: 'WCCustomizeButton',
  props: {
    isRandomWeightedItem: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    async customize() {
      if (this.isRandomWeightedItem) {
        await this.customizeWeight();
      } else {
        await this.customizeMadeToOrder();
      }
    },
    async customizeMadeToOrder() {
      try {
        await ModalService.open(WCItemModifierRoot, {
          item: this.item,
          editingItem: false,
          orderType: this.orderType,
        });
      } catch (error) {
        console.log(error);
      } finally {
        if (this.$refs.customizeBtnRef) this.$refs.customizeBtnRef.focus();
      }
    },
    async customizeWeight() {
      try {
        await ModalService.open(WCRandomWeightModal, {
          item: this.item,
          orderType: this.orderType,
        });
      } catch (error) {
        console.log(error);
      } finally {
        if (this.$refs.customizeBtnRef) this.$refs.customizeBtnRef.focus();
      }
    },
    async goToCustomizePage() {
      if (this.isRandomWeightedItem) {
        await this.goToWeightCustomizePage();
      } else {
        await this.goToMadeToOrderItemCustomizePage();
      }
    },
    goToMadeToOrderItemCustomizePage() {
      this.$router.push({
        name: 'Customize Your Item',
        params: { id: this.item.id, name: this.item.name },
      });
    },
    goToWeightCustomizePage() {
      this.$router.push({
        name: 'Customize Your Item Weight',
        params: { id: this.item.id, name: this.item.name },
      });
    },
  },
  mixins: [CartControlsMixin],
});
