<template>
  <button
    :class="['wc-shopping-cart-icon', 'text-light', 'rounded-circle', 'border', cartBtnVariant]"
    @click.stop.prevent="clicked"
    :aria-label="$t('shoppingCartWithItemsCount', { count: count })"
    v-if="!shouldHideItemPrices"
  >
    <WCSpinner v-if="loading" color="light" size="small" />
    <font-awesome-icon
      v-else
      icon="cart-shopping"
      :size="cartIconSize"
      :color="cartIconColor"
      class="cart-icon"
    />
    <span class="badge badge-pill badge-info wc-shopping-cart-badge font-size-xs">{{ count }}</span>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';
import CartItemControls from '@/modules/cart/mixins/CartControls/CartItemControls';
import HidePricingMixin from '@/mixins/HidePricingMixin';
import WCSpinner from '../WCSpinner/WCSpinner.vue';

export default {
  name: 'WCShoppingCartButton',
  mixins: [CartItemControls, HidePricingMixin],
  components: {
    WCSpinner,
  },
  props: {
    loading: {
      type: Boolean,
    },
    cartIconSize: {
      type: String,
      default: 'sm',
    },
    cartBtnVariant: {
      type: String,
      default: 'secondary',
    },
    cartIconColor: {
      type: String,
    },
  },
  emits: ['showItems'],
  computed: {
    ...mapGetters({
      carts: 'cart/nonEmptyCarts',
      cartsCount: 'cart/getCartsCount',
      isCustomerModeScan: 'user/isCustomerModeScan',
    }),
    count() {
      if (this.$configuration.orderTypesEnabled && !this.isCustomerModeScan) {
        let itemsCount = 0;
        this.carts.forEach(cart => {
          itemsCount += this.cartLineItemCount(cart);
        });
        return itemsCount;
      }
      return this.cartLineItemCount();
    },
  },
  methods: {
    clicked() {
      this.$emit('showItems');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-shopping-cart-icon {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  font-size: $font-size-base;
  background: none;
  &.secondary {
    background-color: var(--secondary, $secondary);
    .cart-icon {
      color: var(--secondary-contrast, get-color-contrast('secondary'));
    }
  }
  &.plain {
    background: none;
  }
}

.wc-shopping-cart-badge {
  position: absolute;
  top: -0.18rem;
  right: -0.375rem;
}

.wc-mobile-nav-cart-icon {
  &.wc-shopping-cart-icon {
    height: 1.3rem;
    z-index: 9999;
  }
}
</style>
