
import { mapGetters, mapActions } from 'vuex';
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import WCAddToCartQuantityAdjuster from '@/modules/cart/components/WCAddToCartQuantityAdjuster/WCAddToCartQuantityAdjuster.vue';
import WCOrderTypesAddToCartDropdown from '@/modules/orderTypes/components/WCOrderTypesAddToCartDropdown/WCOrderTypesAddToCartDropdown.vue';
import { BTN_VARIANT as ATLB_BTN_VARIANT } from '@/constants/AppConstants';
import WCAddToListButton from '@/modules/lists/components/WCAddToListButton/WCAddToListButton.vue';
import ProductDetailMixin from '@/views/ProductDetailPage/mixins/ProductDetailMixin';
import vClickOutside from 'click-outside-vue3';
import { defineComponent, PropType } from 'vue';
import { ENTRY_METHOD, EntryMethod } from '@/models/entities/cart/entry-method';

export default defineComponent({
  name: 'WCBasicAddToCartButton',
  emits: ['addToCart'],
  components: {
    WCAddToCartQuantityAdjuster,
    WCOrderTypesAddToCartDropdown,
    WCAddToListButton,
  },
  mixins: [CartControlsMixin, OrderTypesMixin, ProductDetailMixin],
  props: {
    showMobileCustomize: {
      type: Boolean,
    },
    describedBy: {
      type: String,
    },
    linkButtonType: {
      type: Boolean,
      default: false,
    },
    showAddToCartOption: {
      type: Boolean,
      default: true,
    },
    priceEmbeddedLineItem: {
      type: Object,
      required: false,
    },
    dropdownVariant: {
      type: Boolean,
      default: true,
    },
    variation: {},
    isListItem: {
      type: Boolean,
      default: false,
    },
    addToListVariant: {
      type: String,
      default: ATLB_BTN_VARIANT.ATC,
      required: false,
    },
    entryMethod: {
      type: String as PropType<EntryMethod>,
      required: false,
      default: ENTRY_METHOD.DEFAULT,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    ...mapGetters({
      isScanAndGoMode: 'user/isCustomerModeScan',
      isInStore: 'cart/getInStore',
    }),
    outOfStock() {
      return !this.isInStock(this.item) && !this.isScanAndGoMode;
    },
    inStoreOnly() {
      return this.isInStoreOnly(this.item) && !this.isScanAndGoMode;
    },
    isMto() {
      return this.item.hasModifiers || this.item.hasLinkedItems;
    },
    listOnly() {
      return this.isItemListOnly(this.item) && !this.isScanAndGoMode;
    },
    notAvailable() {
      return (
        (this.item.discontinued && this.outOfStock) ||
        (this.item.discontinued && this.inStoreOnly) ||
        this.getMinimumQuantity(this.item).comparedTo(0) <= 0
      );
    },
    notEligibleForCart() {
      return (
        this.$configuration.orderTypesEnabled && !this.isInActiveOrderType && !this.isScanAndGoMode
      );
    },
    isInActiveOrderType() {
      return (
        this.activeOrderType && this.isItemEligibleForOrderType(this.item, this.activeOrderType)
      );
    },
    eligibleOrderTypes() {
      return this.eligibleOrderTypesForItem(this.item);
    },
    eligibleInactiveOrderTypes() {
      return this.eligibleInactiveOrderTypesForItem(this.item);
    },
    /**
     * Only show the order type dropdown if there are other eligible order types & the item can be added to a cart normally
     */
    showOrderTypeDropdown() {
      return (
        this.dropdownVariant &&
        this.eligibleInactiveOrderTypes.length > 0 &&
        !this.outOfStock &&
        !this.inStoreOnly &&
        !this.notAvailable
      );
    },
    addToListInferredVariant() {
      if (this.linkButtonType) {
        return ATLB_BTN_VARIANT.LINK;
      }
      return this.addToListVariant;
    },
    addToListVariantIsMobileCard() {
      return this.addToListInferredVariant === ATLB_BTN_VARIANT.MOBILE_CARD;
    },
  },
  methods: {
    ...mapActions({
      setInStore: 'cart/setInStore',
      reload: 'cart/reload',
      sync: 'cart/sync',
    }),
    /**
     * Method to call on clicking add to cart button - check if user has scan and go session, increment and move focus to increment button if not
     */
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    hideDropdown() {
      this.showDropdown = false;
    },
    keepDropdown() {
      this.showDropdown = true;
    },
  },
});
