
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import { MODAL_EVENTS } from '@/constants/EventConstants';
import WCSimpleReactiveQuantityAdjustor from '@/components/WCSimpleReactiveQuantityAdjuster/WCSimpleReactiveQuantityAdjuster.vue';
import ToastService from '@/modules/toasts/services/ToastService';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import WCCurrentOrderTypeBadge from '@/modules/orderTypes/components/WCCurrentOrderTypeBadge/WCCurrentOrderTypeBadge.vue';
import { defineComponent } from 'vue';
import WCProductPricing from '@/components/WCProductPricing/WCProductPricing.vue';
import RandomWeightMixin from './mixins/RandomWeightMixin';

export default defineComponent({
  mixins: [ModalMixin, RandomWeightMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    orderType: {
      type: Object,
    },
  },
  data() {
    return {
      MODAL_EVENTS,
    };
  },
  computed: {
    extendedPrice() {
      return this.totalWeight * (this.item.actualPrice / this.item.actualPriceDivider);
    },
    totalWeight() {
      return this.itemQuantityTracker * this.itemWeightedQuantityTracker;
    },
    weightUnit() {
      return this.item.weightProfile.abbrv;
    },
  },
  methods: {
    addToCartAndClose() {
      this.dismiss(MODAL_EVENTS.DISMISS);
      this.addCurrentItemToCart();
    },
    async addCurrentItemToCart() {
      try {
        await this.setQuantity(
          this.itemQuantityTracker,
          this.itemWeightedQuantityTracker,
          this.item,
          '',
          null,
        );
      } catch (error) {
        console.error(error);
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: this.$t('cannotAddItemToCart'),
            message: this.$t('cannotAddItemToCart'),
          },
          actions: {},
          timeout: 5000,
          skipHash: false,
        });
      }
    },
  },
  components: {
    WCProductPricing,
    FontAwesomeIcon,
    WCCurrentOrderTypeBadge,
    WCSimpleReactiveQuantityAdjustor,
    WCModal,
    WCModalHeader,
    WCModalBody,
    WCModalFooter,
  },
});
