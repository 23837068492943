export const ENTRY_METHOD = {
  DEFAULT: 'DEFAULT',
  RECOMMENDATION_SIMILAR: 'RECOMMENDATION_SIMILAR',
  RECOMMENDATION_FREQUENTLY_BOUGHT: 'RECOMMENDATION_FREQUENTLY_BOUGHT',
  RECOMMENDATION_HOME_PAGE: 'RECOMMENDATION_HOME_PAGE',
  RECOMMENDATION_ON_SALE: 'RECOMMENDATION_ON_SALE',
  RECOMMENDATION_COUPON: 'RECOMMENDATION_COUPON',
} as const;

type ObjectValues<T> = T[keyof T];

export type EntryMethod = ObjectValues<typeof ENTRY_METHOD>;
