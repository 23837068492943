import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-size-sm text-primary" }
const _hoisted_2 = { class: "font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('currentSelection')), 1),
    _createTextVNode(),
    _createElementVNode("div", null, _toDisplayString(this.weightIncrement) + " " + _toDisplayString(_ctx.$t('each')), 1),
    _createTextVNode(),
    _createElementVNode("div", null, _toDisplayString(this.totalOrderedWeight) + " " + _toDisplayString(this.weightUnit) + " " + _toDisplayString(_ctx.$t('total')), 1)
  ]))
}