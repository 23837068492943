<template>
  <div class="d-flex">
    <div class="d-flex login-icon align-items-center justify-content-center mr-2">
      <font-awesome-icon icon="right-to-bracket" size="lg" />
    </div>
    <div class="d-flex flex-column">
      <div class="wc-user-greeter__welcome-text text-muted font-size-sm">
        <span>{{ $t('greeting') }} </span>
        <router-link
          :aria-label="$t('name')"
          to="/me/edit"
          class="link-primary underline-link-from-center font-weight-bold font-size-sm ml-1"
        >
          {{ capitalizeFirstLetter(name) }}
        </router-link>
        <div v-if="isEmployee && user.dispShort">
          {{ $t('forText', { name: user.dispShort }) }}
        </div>
      </div>
      <a
        href="#"
        class="link-primary underline-link-from-center font-weight-bold font-size-sm fit-content"
        data-testid="logOutButton"
        @click.prevent="$emit('logout')"
      >
        {{ isCustomer ? $t('signOut') : $t('logOut') }}
      </a>
    </div>
  </div>
</template>
<script>
import UserMixin from '@/modules/user/mixins/UserMixin';
import { capitalizeFirstLetter } from '@/utils/formatters';

export default {
  mixins: [UserMixin],
  emits: ['logout'],
  methods: {
    capitalizeFirstLetter,
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.login-icon {
  width: 3rem;
  height: 3rem;
  color: var(--primary);
}

.fit-content {
  width: fit-content;
}
</style>
