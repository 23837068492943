<template>
  <header class="wc-mheader__wrapper container-fluid" :class="scrollClass">
    <section class="wc-mheader__middle py-2">
      <div class="row">
        <WCHamburgerMenu @showMenu="toggleSideMenu" />
        <!-- Allow navigation to home only if not an employee -->
        <div class="col-6 my-auto" v-if="isNotAnEmployee">
          <router-link class="float-left" to="/" v-if="!isCustomerModeScan">
            <WCImage
              v-if="getStoreImageSrc"
              :imageSrc="getStoreImageSrc"
              :imageAlt="getStoreName"
              imageClass="logo"
              :defaultImage="false"
            />
          </router-link>
          <div v-else>
            <WCImage
              v-if="getStoreImageSrc"
              :imageSrc="getStoreImageSrc"
              :imageAlt="getStoreName"
              imageClass="logo"
              :defaultImage="false"
            />
          </div>
        </div>
        <div class="ml-3" v-else>
          <WCImage
            v-if="getStoreImageSrc"
            :imageSrc="getStoreImageSrc"
            :imageAlt="getStoreName"
            imageClass="logo"
            :defaultImage="false"
          />
        </div>
        <div class="col row header-options justify-content-end" v-if="isNotAnEmployee">
          <div
            class="wc-header__qrcode text-secondary rounded-circle border ml-auto wc-header__barcode"
            v-if="isCustomerModeScan && showScanIcon"
          >
            <router-link to="/scan">
              <font-awesome-icon class="text-primary-contrast" icon="barcode-read" />
            </router-link>
          </div>
          <div
            class="wc-header__qrcode text-secondary rounded-circle border"
            v-if="isCustomerModeScan"
          >
            <router-link to="/me/lists">
              <font-awesome-icon class="text-primary-contrast" icon="list-check" />
            </router-link>
          </div>
          <div
            class="wc-header__search text-secondary rounded-circle border ml-auto"
            v-if="$configuration.itemSearchEnabled && !isCustomerModeScan"
          >
            <router-link to="/msearch" :aria-label="$t('search')">
              <font-awesome-icon class="text-primary-contrast" icon="magnifying-glass" />
            </router-link>
          </div>

          <div class="mr-3" v-if="$configuration.cartEnabled || isCustomerModeScan">
            <WCShoppingCartButton @showItems="goToMyCart" :loading="isSyncing" cartIconSize="lg" />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="d-flex align-items-baseline pb-2">
        <WCStoreLabelSmall class="mr-2" />
        <WCActiveOrderTypeBadge />
      </div>
    </section>
    <section
      class="d-flex justify-content-center"
      v-if="
        isGiftCard &&
          !$configuration.orderTypesEnabled &&
          ($route.name === 'Browse' ||
            $route.name === 'Home' ||
            $route.name === 'Product Detail Page' ||
            $route.name === 'List') &&
          isNotAnEmployee
      "
    >
      <div class="alert alert-danger mt-3 col" role="alert">
        {{ $t('eGiftCardCartError') }}
      </div>
    </section>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserMixin from '@/modules/user/mixins/UserMixin';
import SideMenuService from '@/modules/sidebar/services/SideMenuService';
import WCShoppingCartButton from '@/components/WCShoppingCartButton/WCShoppingCartButton.vue';
import WCHamburgerMenu from '@/components/WCHamburgerMenu/WCHamburgerMenu.vue';
import HeaderScrollTransitionMixin from '@/modules/platform/mixins/HeaderScrollTransitionMixin';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';
import WCImage from '@/components/WCImage/WCImage.vue';
import CartItemControls from '@/modules/cart/mixins/CartControls/CartItemControls';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import WCStoreLabelSmall from '@/components/WCStoreLabelSmall/WCStoreLabelSmall.vue';
import WCActiveOrderTypeBadge from '@/modules/orderTypes/components/WCActiveOrderTypeBadge/WCActiveOrderTypeBadge.vue';

export default {
  mixins: [
    HeaderScrollTransitionMixin,
    UserMixin,
    EmployeeMixin,
    CartItemControls,
    OrderTypesMixin,
  ],
  components: {
    WCActiveOrderTypeBadge,
    WCStoreLabelSmall,
    WCShoppingCartButton,
    WCHamburgerMenu,
    WCImage,
  },
  /**
   * Created hook to make the store as default if there is only one store i.e. multistore is false
   */
  created() {
    this.updateHomeStore();
  },
  computed: {
    ...mapGetters({
      isSyncing: 'cart/isSyncing',
      giftCardItems: 'cart/getGiftCardItems',
      isGiftCard: 'cart/isGiftCard',
      cartsCount: 'cart/getCartsCount',
    }),
    getStoreImageSrc() {
      return `${this.$configuration.cdn}/api/img/${this.$configuration.store.image}`;
    },
    getMenu() {
      return this.$configuration.mainMenu;
    },
    getStoreName() {
      return this.$configuration.store.name;
    },
    isMultiStore() {
      return this.$configuration.multistore;
    },
    showScanIcon() {
      return this.$route.name !== 'Scan Products';
    },
  },
  methods: {
    ...mapActions({ fetchSuggestions: 'browse/fetchSuggestions' }),
    // if in mobile view, clicking on cart icon navigate to mobile cart page /mcart
    goToMyCart() {
      if (this.$route.name !== 'Mobile Cart') this.$router.push('/mcart');
    },
    toggleSideMenu() {
      SideMenuService.toggle();
    },
    switchLocale(locale) {
      this.$i18n.locale = locale.code;
      localStorage.setItem('locale', locale.code);
    },
    /**
     * Make the store as default if there is only one store i.e. multistore is false
     */
    updateHomeStore() {
      if (!this.isMultiStore) {
        this.setUserHomeStore(this.$configuration.store.id);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-mheader__wrapper {
  padding-top: $pad-2;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: map-get($zindex, 'header');
  border-bottom: 4px solid var(--secondary, $secondary);
  background-color: var(--white);

  @include media-breakpoint-down(sm) {
    &.scrolled-down {
      transform: translateY(-100%);
      transition: all 0.3s ease-in-out;
    }

    &.scrolled-up {
      transform: translateY(0);
      transition: all 0.3s ease-in-out;
    }
  }
}

.wc-mheader__middle {
  .logo {
    max-width: 100%;
    max-height: 3rem;
  }
}

.wc-header__search {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: 3rem;
  height: 3rem;
  font-size: $font-size-xl;
  background-color: var(--primary, $primary);
}

.wc-header__qrcode {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: 3rem;
  height: 3rem;
  font-size: $font-size-xl;
  background-color: var(--primary, $primary);
}

.wc-search__disabled {
  text-align: right;
}

.header-options {
  gap: rem(3px);
}

@media only screen and (max-width: 374px) {
  .wc-header__barcode {
    display: none;
  }
}
</style>
