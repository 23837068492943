<template>
  <header class="wc-header__wrapper shadow-sm" role="banner" ref="wc_header">
    <WCHeaderTop
      :isEmployee="isEmployee"
      :isCustomer="isCustomer"
      :isCustomerModeScan="isCustomerModeScan"
      v-if="isNotAnEmployee"
    />
    <section class="wc-header__middle p-3 container-xl border-bottom">
      <div class="header-row">
        <div class="left-area d-flex align-items-center mr-1">
          <!-- Allow navigation to home only if not an employee -->
          <div class="mr-3" v-if="isNotAnEmployee">
            <router-link to="/">
              <WCImage
                :imageSrc="getStoreImageSrc"
                :imageAlt="getStoreName"
                imageClass="logo"
                :defaultTitle="getStoreName"
              />
            </router-link>
          </div>
          <div class="mr-3" v-else>
            <WCImage
              :imageSrc="getStoreImageSrc"
              :imageAlt="getStoreName"
              imageClass="logo"
              :defaultTitle="getStoreName"
            />
          </div>
          <div class="desktop-only">
            <WCStoreLabel />
          </div>
        </div>
        <div class="center-area">
          <WCSearchBar
            v-if="
              $route.name !== 'Gift Card' && isNotAnEmployee && $configuration.itemSearchEnabled
            "
            @search="search"
            @fetchSuggestions="fetchSuggestions"
            :suggestions="suggestions"
            :itemGroups="itemGroups"
          />
        </div>
        <div class="right-area d-flex justify-content-end align-items-center ml-1">
          <div class="desktop-only mr-4">
            <WCActiveOrderTypeBadge />
          </div>
          <div class="d-flex align-items-center">
            <WCLoginGreeter @logout="logout" />
            <WCShoppingCartButton
              v-if="isNotAnEmployee && ($configuration.cartEnabled || isCustomerModeScan)"
              @showItems="toggleCartDrawer"
              class="ml-4"
              :loading="isSyncing"
              cartIconSize="lg"
              id="headerCartButton"
            />
          </div>
        </div>
      </div>
      <div class="tablet-only d-flex align-items-center mt-2">
        <WCStoreLabelSmall class="mr-2" />
        <WCActiveOrderTypeBadge />
      </div>
    </section>
    <WCNavBar :wcHeaderHeight="wcHeaderHeight" />
    <section class="d-flex justify-content-center" v-if="showGiftCardError">
      <div class="alert alert-danger mt-3 col-8" role="alert">
        {{ $t('eGiftCardCartError') }}
      </div>
    </section>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';
import UserMixin from '@/modules/user/mixins/UserMixin';
import WCImage from '@/components/WCImage/WCImage.vue';
import WCNavBar from '@/components/WCNavBar/WCNavBar.vue';
import CartItemControls from '@/modules/cart/mixins/CartControls/CartItemControls';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import WCStoreLabel from '@/components/WCStoreLabel/WCStoreLabel.vue';
import WCActiveOrderTypeBadge from '@/modules/orderTypes/components/WCActiveOrderTypeBadge/WCActiveOrderTypeBadge.vue';
import WCLoginGreeter from '@/components/WCLoginGreeter/WCLoginGreeter.vue';
import WCStoreLabelSmall from '@/components/WCStoreLabelSmall/WCStoreLabelSmall.vue';
import WCSearchBar from '../WCSearchBar/WCSearchBar.vue';
import WCShoppingCartButton from '../WCShoppingCartButton/WCShoppingCartButton.vue';
import WCHeaderTop from '../WCHeaderTop/WCHeaderTop.vue';

export default {
  mixins: [UserMixin, EmployeeMixin, CartItemControls, OrderTypesMixin],
  components: {
    WCStoreLabelSmall,
    WCSearchBar,
    WCShoppingCartButton,
    WCNavBar,
    WCHeaderTop,
    WCImage,
    WCStoreLabel,
    WCActiveOrderTypeBadge,
    WCLoginGreeter,
  },
  data() {
    return {
      wcHeaderHeight: '0px',
    };
  },
  computed: {
    ...mapGetters({
      suggestions: 'browse/getSuggestions',
      isSyncing: 'cart/isSyncing',
      isGiftCard: 'cart/isGiftCard',
      cartsCount: 'cart/getCartsCount',
    }),
    getStoreImageSrc() {
      return `${this.$configuration.cdn}/api/img/${this.$configuration.store.image}`;
    },
    getStoreName() {
      return this.$configuration.store.name;
    },
    itemGroups() {
      return this.$configuration.mainMenu?.children;
    },
    isMultiStore() {
      return this.$configuration.multistore;
    },
    showGiftCardError() {
      return (
        this.isGiftCard &&
        (this.$route.name === 'Browse' ||
          this.$route.name === 'Home' ||
          this.$route.name === 'Product Detail Page' ||
          this.$route.name === 'List') &&
        this.isNotAnEmployee &&
        !this.$configuration.orderTypesEnabled
      );
    },
  },
  /**
   * Created hook to make the store as default if there is only one store i.e. multistore is false
   */
  async created() {
    this.updateHomeStore();
  },
  methods: {
    ...mapActions({ fetchSuggestions: 'browse/fetchSuggestions' }),
    search({ term, department, itemGroup }) {
      this.$router.push({
        name: 'Browse',
        query: { q: term, g: itemGroup, ...(department ? { Dept: [department] } : undefined) },
      });
    },
    toggleCartDrawer() {
      this.emitter.$emit('cartDrawer:toggle');
    },
    /**
     * Make the store as default if there is only one store i.e. multistore is false
     */
    updateHomeStore() {
      if (!this.isMultiStore) {
        this.setUserHomeStore(this.$configuration.store.id);
      }
    },
  },
  mounted() {
    this.wcHeaderHeight = window.getComputedStyle(this.$refs.wc_header).height;
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-header__wrapper {
  position: sticky;
  top: 0;
  z-index: map-get($zindex, 'header');

  background-color: white;
}

.wc-header__middle {
  .logo {
    max-width: 100%;
    max-height: 3.5rem;
  }
}

.header-row {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-template-areas: 'left left left left center center center center right right right right';
  @include media-breakpoint-down(lg) {
    grid-template-areas: 'left left left center center center center center center right right right';
  }
}

.left-area {
  grid-area: left;
  justify-self: start;
}

.center-area {
  grid-area: center;
}

.right-area {
  grid-area: right;
  justify-self: end;
}

.desktop-only {
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.tablet-only {
  @include media-breakpoint-up(xl) {
    display: none !important;
  }
}
</style>
