<template>
  <div class="wc-card-wrapper">
    <div class="card wc-card shadow-sm">
      <!-- START : Product Card Header -->
      <div class="wc-header-image-wrapper border-secondary">
        <!-- START : Product Image -->
        <router-link
          :to="`/i/${item.id}`"
          :aria-label="`${item.brand} ${item.name} see description in page`"
          class="d-block w-100 h-100"
        >
          <WCImage
            :imageSrc="getProductImage(item.image)"
            :imageAlt="productImgAltText(item.name, item.brand)"
            imageClass="wc-header-image"
            defaultClass="w-100 h-100 p-5"
            :defaultTitle="noProductImageAltText(item.name)"
          />
        </router-link>
        <!-- END : Product Image -->

        <!-- START : Promo Ribbon Wrap -->
        <div class="wc-promo-ribbon-wrap">
          <WCPromoRibbon :product="item" :ribbonType="promoRibbonType.SALE_AMOUNT_RIBBON" />
          <WCPromoRibbon :product="item" :ribbonType="promoRibbonType.DISCOUNT_NAME_RIBBON" />
        </div>
        <!-- END : Promo Ribbon Wrap -->

        <!-- START: Fav icon Button-->
        <WCFavoriteButton
          class="wc-product-card__favorite-btn"
          v-if="isCustomer"
          :item="item"
          :isMobile="false"
        />
        <!-- END: Fav icon Button-->
      </div>
      <!-- END : Product Card Header -->

      <!-- START : Product Card Body -->
      <div class="card-body wc-card-body d-flex flex-column">
        <!-- START : Product Card Body Top Wrap -->
        <div class="d-flex flex-column flex-grow-1">
          <!-- START : Product Name -->
          <router-link
            :to="`/i/${item.id}`"
            class="wc-product-card__name link-dark underline-link-from-center"
            :aria-label="`Product ${item.name}`"
            :id="item.id"
          >
            {{ item.name }}
            <div v-if="item.size">{{ item.size }}</div>
          </router-link>
          <!-- END : Product Name -->
          <div v-if="item.brand" class="wc-product-card__brand d-flex mb-2">
            <router-link
              :aria-label="`Brand ${item.brand}`"
              :to="`/b?g=BRD-${item.brandId}`"
              class="link-primary underline-link-from-center font-size-xs"
            >
              {{ item.brand }}
            </router-link>
          </div>
        </div>
        <!-- END : Product Card Body Top Wrap -->

        <div class="d-flex flex-column flex-grow-1 justify-content-end">
          <div class="d-flex flex-column justify-content-end">
            <div class="d-flex flex-row justify-content-between mb-1 flex-grow-1">
              <div class="d-flex justify-content-between flex-fill">
                <WCProductPricing
                  class="text-right flex-fill font-size-sm"
                  :item="item"
                  v-if="!shouldHideItemPrices"
                />
                <div
                  v-if="isCustomer && $configuration.allowCouponClipping && hasCoupons"
                  class="wc-coupon-card-desktop__coupon-icon link-primary pb-1 pl-1"
                  @click.stop="openCouponsModal"
                >
                  <font-awesome-icon icon="scissors" size="lg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- START : Product Card Body Bottom Wrap -->
        <div class="wc-product-card__bottom-wrapper">
          <div class="d-flex flex-column justify-content-between">
            <!-- START : Product Payment Option Badges -->
            <WCPaymentOptionBadges class="mb-2" :item="item" />
            <!-- END : Product Payment Option Badges -->

            <!-- START : Product Btn Section -->
            <WCBasicAddToCartButton
              :item="item"
              class="btn-block"
              :describedBy="item.id"
              :orderType="activeOrderType"
              :entryMethod="entryMethod"
              v-if="!isCustomerModeScan && !shouldHideItemPrices"
            />
            <router-link
              v-else-if="!isCustomerModeScan && shouldHideItemPrices"
              class="btn btn-primary btn-block wc-atc-btn wc-atc-btn--basic"
              to="/login"
            >
              {{ $t('signInToShop') }}
            </router-link>
            <!-- END : Product Btn Section -->
          </div>
          <!-- END : Product Card Body Bottom Wrap -->
        </div>
        <!-- END : Product Card Body -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WCFavoriteButton from '@/modules/lists/components/WCFavoriteButton/WCFavoriteButton.vue';
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import ProductImageInfoMixin from '@/mixins/ProductImageInfoMixin';
import { PROMO_RIBBON_TYPE } from '@/constants/PromoRibbonConstants';
import WCPromoRibbon from '@/components/WCPromoRibbon/WCPromoRibbon.vue';
import WCProductPricing from '@/components/WCProductPricing/WCProductPricing.vue';
import WCImage from '@/components/WCImage/WCImage.vue';
import ProductDetailMixin from '@/views/ProductDetailPage/mixins/ProductDetailMixin';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import modalService from '@/modules/modals/services/modal.service';
import WCSimpleToastVue from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ToastService from '@/modules/toasts/services/ToastService';
import WCAvailableCouponsModal from '@/modules/coupons/components/WCAvailableCouponsModal/WCAvailableCouponsModal.vue';
import HidePricingMixin from '@/mixins/HidePricingMixin';
import WCPaymentOptionBadges from '@/components/WCPaymentOptionBadges/WCPaymentOptionBadges.vue';
import { defineAsyncComponent, defineComponent } from 'vue';
import { ENTRY_METHOD } from '@/models/entities/cart/entry-method';

export default defineComponent({
  components: {
    WCBasicAddToCartButton: defineAsyncComponent(() =>
      import('@/modules/cart/components/WCBasicAddToCartButton/WCBasicAddToCartButton.vue'),
    ),
    WCProductPricing,
    WCPromoRibbon,
    WCFavoriteButton,
    WCImage,
    WCPaymentOptionBadges,
  },
  mixins: [
    CartControlsMixin,
    ProductImageInfoMixin,
    ProductDetailMixin,
    OrderTypesMixin,
    HidePricingMixin,
  ],
  props: {
    entryMethod: {
      required: false,
      default: ENTRY_METHOD.DEFAULT,
    },
  },
  data() {
    return {
      selected: '',
      placeholder: '',
    };
  },
  methods: {
    async openCouponsModal() {
      try {
        await modalService.open(WCAvailableCouponsModal, { item: this.item });
      } catch (error) {
        if (error !== 'Dismissed')
          ToastService.open(WCSimpleToastVue, {
            props: {
              variant: 'danger',
              title: this.$t('error'),
              message: this.$t('showCouponMoreInfoFailure'),
            },
            timeout: 7000,
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      isCustomer: 'user/isCustomer',
      isCustomerModeScan: 'user/isCustomerModeScan',
    }),
    hasModifiers() {
      return this.item.hasModifiers;
    },
    /**
     * Method to get the ribbon type key
     */
    promoRibbonType() {
      return PROMO_RIBBON_TYPE;
    },
    hasCoupons() {
      return this.item?.coupons && this.item?.coupons?.length > 0;
    },
  },
});
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-card-wrapper {
  // The 2 below css lines make height of cards equal
  display: flex;
  flex-direction: row;
}

.wc-product-card__favorite-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.wc-card {
  border-radius: 5px;
  width: 100%;
}

.wc-coupon-card-desktop__coupon-icon {
  color: var(--primary, $primary);
  cursor: pointer;
  display: flex;
}

.wc-header-image-wrapper {
  display: inline-block;
  position: relative;
  padding: $pad-2 * 1.25;
  max-width: 100%;
  height: 15rem;
  border-bottom: 1px solid #ddd;

  .wc-promo-ribbon-wrap {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }
}

.wc-header-image {
  position: absolute;
  top: 2.5rem;
  bottom: 0;
  left: 0;
  right: 0;
  padding: $pad-2 * 1.25;

  object-fit: scale-down;
  max-height: calc(100% - 2.5rem);
  max-width: 100%;
  margin: auto;
}

.wc-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wc-card-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &.wc-middle-content {
    align-items: center; /* Vertically aligns brand and 'Add to list' button */
  }

  .wc-card-select {
    width: rem(80px);
    margin-top: $margin-2;
  }

  .wc-card-button {
    height: rem(38px);
    min-width: rem(120px);
    white-space: nowrap;
    margin-top: $margin-2;
  }

  .wc-list-button {
    padding-right: 0;
  }
}
</style>
