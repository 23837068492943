<template>
  <div>
    <h1 ref="focusFirst" class="sr-only">{{ name }} {{ $t('homePage') }}</h1>
    <WCProductRow
      class="my-md-3"
      v-for="row in rows"
      :key="row.id"
      :rowId="row.id"
      :rowName="row.name"
    />
    <WCCouponRow
      v-if="$configuration.allowCouponClipping && !hideCoupons"
      class="my-md-3"
      :key="'coupon:homepage'"
    />
  </div>
</template>

<script>
import WCProductRow from '@/components/WCProductRow/WCProductRow.vue';
import WCCouponRow from '@/components/WCCouponRow/WCCouponRow.vue';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    rows: {
      type: Array,
    },
    ads: {
      type: Array,
    },
    hideCoupons: {
      type: Boolean,
      default: false,
    },
  },
  components: { WCProductRow, WCCouponRow },
};
</script>
