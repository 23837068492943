
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mapGetters } from 'vuex';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';

export default {
  components: [FontAwesomeIcon],
  mixins: [EmployeeMixin],
  computed: {
    ...mapGetters({ getUser: 'user/getUser' }),
    storeName() {
      return this.$configuration.store.name;
    },
    isMultiStore() {
      return this.$configuration.multistore;
    },
  },
};
