import CartControls from '@/modules/cart/mixins/CartControls/CartControls';
import { BigNumber } from 'bignumber.js';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [CartControls],
  created() {
    const existingCartQuantity = this.recoverUnroundedQuantityFromCart(this.item, null);
    if (existingCartQuantity.comparedTo(new BigNumber(0))) {
      this.itemQuantityTracker = existingCartQuantity;
      this.itemWeightedQuantityTracker = this.getWeightedQuantityInCart(this.item, null);
    }
  },
  data() {
    return {
      itemQuantityTracker: this.getMinimumQuantity(this.item),
      itemWeightedQuantityTracker: this.getMinimumWeightedQuantity(this.item),
    };
  },
  computed: {
    randomWeightItemQuantity: {
      get() {
        return this.itemQuantityTracker;
      },
      set(x) {
        this.itemQuantityTracker = x;
      },
    },
    randomWeightItemWeightedQuantity: {
      get() {
        return this.itemWeightedQuantityTracker;
      },
      set(x) {
        this.itemWeightedQuantityTracker = x;
      },
    },
    noMax() {
      return new BigNumber('Infinity');
    },
    displayItemQuantity() {
      return this.getDisplayQuantity(this.item, this.randomWeightItemQuantity);
    },
    minimumItemQuantity() {
      return this.getMinimumQuantity(this.item);
    },
    randomItemIncrement() {
      return this.getQuantityIncrement(this.item);
    },
    isNextItemQuantityIncrementValid() {
      return this.isNextQuantityIncrementValid(
        this.item,
        this.randomWeightItemQuantity,
        this.randomWeightItemWeightedQuantity,
      );
    },
    isNextItemQuantityDecrementValid() {
      return this.isNextQuantityDecrementValid(
        this.item,
        this.randomWeightItemQuantity,
        this.randomWeightItemWeightedQuantity,
      );
    },
    displayRandomWeightedQuantity() {
      if (!this.isRandomWeightItem) {
        return '';
      }
      return this.getDisplayWeightedQuantity(this.item, this.randomWeightItemWeightedQuantity);
    },
    minimumRandomWeightedQuantity() {
      return this.getMinimumWeightedQuantity(this.item);
    },
    randomWeightedQuantityIncrement() {
      if (!this.isSoldByRandomWeight(this.item)) {
        return new BigNumber('NaN');
      }
      return this.getWeightedQuantityIncrement(this.item);
    },
    isNextRandomWeightedQuantityIncrementValid() {
      if (!this.isRandomWeightItem) {
        return false;
      }
      return this.isNextWeightedQuantityIncrementValid(
        this.item,
        this.randomWeightItemQuantity,
        this.randomWeightItemWeightedQuantity,
      );
    },
    isNextRandomWeightedQuantityDecrementValid() {
      if (!this.isRandomWeightItem) {
        return false;
      }
      return this.isNextWeightedQuantityDecrementValid(
        this.item,
        this.randomWeightItemQuantity,
        this.randomWeightItemWeightedQuantity,
      );
    },
    isRandomWeightItem() {
      return this.isSoldByRandomWeight(this.item);
    },
  },
  methods: {
    /**
     * Reset quantity and weighted quantity to their minimums.
     */
    resetQuantities() {
      this.randomWeightItemQuantity = this.getMinimumQuantity(this.item);
      this.randomWeightItemWeightedQuantity = this.getMinimumWeightedQuantity(this.item);
    },
  },
});
