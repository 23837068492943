
import { defineComponent } from 'vue';
import CartControls from '@/modules/cart/mixins/CartControls/CartControls';

export default defineComponent({
  mixins: [CartControls],
  props: {
    lineItem: {
      type: Object,
    },
  },
  computed: {
    weightUnit() {
      return this.lineItem.item.weightProfile.abbrv;
    },
    weightIncrement() {
      return this.getDisplayQuantityInCart(this.lineItem.item, this.lineItem.variation);
    },
    totalOrderedWeight() {
      return this.getTotalBaseQuantityInCart(this.lineItem.item, this.lineItem.variation);
    },
  },
});
