import OrderType from '@/models/entities/cart/order-type';

export default function isGiftCardsEnabled(
  giftCardsConfigured: boolean,
  orderTypesEnabled: boolean,
  orderTypes: Array<OrderType>,
): boolean {
  return (
    giftCardsConfigured &&
    (!orderTypesEnabled || orderTypes?.filter(orderType => orderType.giftCard).length > 0)
  );
}
