
import UserMixin from '@/modules/user/mixins/UserMixin';
import WCUserGreeter from '@/components/WCUserGreeter/WCUserGreeter.vue';
import WCLoginMenu from '@/components/WCLoginMenu/WCLoginMenu.vue';

export default {
  components: { WCLoginMenu, WCUserGreeter },
  mixins: [UserMixin],
  emits: ['logout'],
};
