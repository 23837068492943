
import WCCarousel from '@/components/WCCarousel/WCCarousel.vue';
import { Splide, SplideTrack } from '@splidejs/vue-splide';
import { PlatformMixin } from '@/modules/platform';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    WCCarousel,
    Splide,
    SplideTrack,
  },
  props: {
    objectList: {
      type: Array,
      default: undefined,
    },
    itemsPerPage: {
      type: Number,
      default: 1,
    },
  },
  mixins: [PlatformMixin],
  data() {
    return {
      showArrows: true,
    };
  },
  mounted() {
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.setArrowVisibility();
    },
    setArrowVisibility() {
      this.showArrows = this.itemsPerPage < this.objectList.length;
    },
  },
  watch: {
    itemsPerPage() {
      this.setArrowVisibility();
    },
  },
});
