<template>
  <PlatformRenderer>
    <template v-slot:onMobile>
      <button
        class="btn btn-primary d-flex justify-content-between align-items-center w-100"
        @click="customize()"
      >
        <div class="ml-2" />
        {{ $t('customize') }}<font-awesome-icon icon="wand-magic-sparkles" class="mx-2" size="sm" />
      </button>
    </template>
  </PlatformRenderer>
</template>

<script>
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { PlatformRenderer },
  name: 'WCCustomizeButtonMobile',
  props: {
    isRandomWeightedItem: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    async customize() {
      if (this.isRandomWeightedItem) {
        await this.customizeWeight();
      } else {
        await this.customizeMadeToOrder();
      }
    },
    async customizeMadeToOrder() {
      this.$router.push({
        name: 'Customize Your Item',
        params: { id: this.item.id, name: this.item.name, orderType: this.orderType?.id },
      });
    },
    async customizeWeight() {
      this.$router.push({
        name: 'Customize Your Item Weight',
        params: { id: this.item.id, name: this.item.name, orderType: this.orderType?.id },
      });
    },
  },
  mixins: [CartControlsMixin],
});
</script>
