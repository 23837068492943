<template>
  <div class="desktop-search-bar" :class="{ 'focusable-area': hasFocus }">
    <WCInputGroup class="flex-nowrap">
      <template v-slot:left>
        <WCItemGroupSelector
          @change="itemGroupSelected"
          :itemGroups="dropdownItemGroups"
          class="btn btn btn-outline"
        />
      </template>
      <div
        class="wc-search-area"
        role="combobox"
        :aria-label="$t('search')"
        :aria-expanded="showSuggestions ? 'true' : 'false'"
        :aria-owns="listboxAriaTarget"
        aria-haspopup="listbox"
      >
        <WCInput
          class="wc-search-box"
          :class="isClearSearchAvailable && 'border-right-0'"
          ref="searchInput"
          @focusin="focusInSearch(searchInput)"
          @focusout="hideModal"
          :placeholder="$t('search')"
          v-model="searchInput"
          @update:modelValue="fetchSuggestions"
          @keydown.enter="submitSearch(searchInput)"
          @keydown.down="handleKeyDown()"
          @keydown.up="handleKeyUp()"
          @keydown.esc="handleEscapeKey()"
          :aria-label="$t('search')"
          :aria-controls="listboxAriaTarget"
          :aria-activedescendant="activeSuggestionIndex !== -1 ? 'selected-search-option' : null"
          aria-autocomplete="list"
        />
        <ul
          class="dropdown-menu show"
          v-if="showSuggestions && suggestions && suggestions.length && searchInput"
          role="listbox"
          id="search-listbox"
          :aria-labelledby="$t('suggestionsList')"
        >
          <li
            class="wrapper"
            v-for="(suggestion, index) in suggestions"
            :key="index"
            :value="suggestion"
            @keydown.enter="submitSearch(suggestion.name)"
            @mousedown="submitSearch(suggestion.name)"
            @mousemove="activeSuggestionIndex = index"
            role="option"
            :id="activeSuggestionIndex === index && 'selected-search-option'"
            :aria-selected="true && activeSuggestionIndex === index"
          >
            <span
              class="wc-suggestion dropdown-item"
              :class="{ active: activeSuggestionIndex === index }"
            >
              {{ suggestion.name }}

              <span
                @mousedown.stop="submitSearch(suggestion.name, suggestion.department)"
                @keydown.enter="submitSearch(suggestion.name, suggestion.department)"
                class="wc-department dropdown-item"
                :class="{ active: activeSuggestionIndex === index }"
                v-if="index === 0 && suggestion.department"
              >
                {{ $t('in') }} {{ suggestion.department }}
              </span>
            </span>
          </li>
        </ul>
      </div>

      <template v-slot:right>
        <button
          class="btn wc-clear-button border-dark border-left-0 border-right-0"
          @click="onClickClearIcon"
          v-if="isClearSearchAvailable"
          :aria-label="$t('clear')"
        >
          <font-awesome-icon class="text-primary" :icon="['fa', 'xmark']" />
        </button>
        <button
          @click="submitSearch(searchInput)"
          class="btn btn-primary wc-search-button"
          :aria-label="$t('search')"
        >
          <font-awesome-icon icon="magnifying-glass" />
        </button>
      </template>
    </WCInputGroup>
  </div>
</template>

<script>
import WCInputGroup from '@/modules/forms/components/WCInputGroup/WCInputGroup.vue';
import WCInput from '@/modules/forms/components/WCInput/WCInput.vue';
import SearchComboBoxMixin from '@/mixins/SearchComboBoxMixin';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import WCItemGroupSelector from '../WCItemGroupSelector/WCItemGroupSelector.vue';

export default {
  name: 'WCSearchBar',
  components: { WCInputGroup, WCInput, WCItemGroupSelector },
  mixins: [SearchComboBoxMixin, OrderTypesMixin],
  computed: {
    dropdownItemGroups() {
      return this.searchableOrderTypes || this.itemGroups;
    },
    listboxAriaTarget() {
      return this.showSuggestions ? 'search-listbox' : null;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-search-area {
  position: relative;
  flex: 1 1 auto;

  .wc-search-box {
    border-radius: 0;
    &.form-control:focus {
      box-shadow: none;
    }
    .form-group-sm .form-control {
      font-size: $font-size-base;
    }
  }

  .dropdown-menu {
    right: 0;
    padding: 0 0 $pad-2;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    /** On change default bootstrap colour to the secondary brand colour **/
    .dropdown-item {
      &.active,
      &:active,
      &:hover,
      &:focus {
        background-color: var(--secondary-lighter-7, get-color('secondary', 'lighter-7'));
        color: var(--secondary-contrast, get-color-contrast('secondary'));
        cursor: pointer;
      }
    }
  }

  .wc-suggestion {
    right: 0;
    padding: 0;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    padding-left: $pad-2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .wc-department {
    font-size: $font-size-xs;
    color: var(--gray);
    padding: 0 0 $pad-2 $pad-3;
    &.active {
      color: inherit;
    }
    &:hover {
      font-weight: 600;
    }
  }
}
</style>
