import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-center font-size-sm badge badge-primary badge-pill px-3 py-2 text-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.activeOrderType && _ctx.isNotAnEmployee)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.activeOrderType.name), 1))
    : _createCommentVNode("", true)
}