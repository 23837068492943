import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import isGiftCardsEnabled from '@/utils/gift-card-utils';

export default {
  mixins: [OrderTypesMixin],
  computed: {
    /**
     * @returns The URL for the E-Gift Card builder
     */
    giftCardUrl() {
      const giftCardOrderType = this.$configuration.orderTypes?.find(
        orderType => orderType.giftCard,
      );

      return giftCardOrderType ? `/orders/${giftCardOrderType.id}` : `/gc`;
    },
    showGiftCardLinks() {
      return isGiftCardsEnabled(
        this.$configuration.allowEGiftCards,
        this.$configuration.orderTypesEnabled,
        this.visibleOrderTypes,
      );
    },
  },
};
