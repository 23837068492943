<template>
  <div class="wc-pricing" :class="{ 'wc-scan-pricing': isScanMode }">
    <div
      v-if="hasActualPrice && !isAdditionalScanCode"
      ref="actualPrice"
      class="font-weight-bold text-primary"
      :class="{
        'wc-pricing__actual--sale': isDiscounted,
        'text-left text-primary': isSoldByAverageWeight,
      }"
      :aria-describedby="`${item.id}-priceDesc`"
    >
      {{ actualPriceDisplay }}
      <span class="sr-only" :id="`${item.id}-priceDesc`">
        {{ isDiscounted ? $t('salePrice') : $t('productPrice') }}
      </span>

      <template v-if="hasAcoDiscountToolTip">
        <WCToolTip target="actualPrice" position="top-end" class="wc-pricing__tooltip">
          <div class="whitespace-pre">{{ acoDiscountToolTipText }}</div>
        </WCToolTip>
        <font-awesome-icon icon="circle-question" />
      </template>
      <template v-else-if="isDiscountedInCart">
        <WCToolTip target="actualPrice" position="top-end" class="wc-pricing__tooltip">
          <div class="whitespace-pre">{{ $t('discountAppliedInCart') }}</div>
        </WCToolTip>
        <font-awesome-icon icon="circle-question" />
      </template>
    </div>
    <div v-else-if="!isSoldByAverageWeight" ref="noPrice">
      {{ $t('seePriceInCart') }}
    </div>
    <div
      v-if="hasActualPrice && isDiscounted"
      class="text-muted"
      :aria-describedby="`${item.id}-priceText`"
      :class="{ 'text-left': isSoldByAverageWeight }"
    >
      <s>{{ suggestedPriceDisplay }}</s>
      <span class="sr-only" :id="`${item.id}-priceText`">{{ $t('productPrice') }}</span>
    </div>
    <div
      v-if="hasActualPrice && isSoldByAverageWeight"
      class="font-weight-bold wc-sold-by-avg-weight"
      ref="avgPriceText"
      :aria-describedby="`${item.id}-avgPriceText`"
    >
      {{ avgPerUnitPrice }}
      <span class="sr-only" :id="`${item.id}-avgPriceText`">{{ $t('averagePerUnitPrice') }}</span>
      <WCToolTip target="avgPriceText" position="top-end" class="wc-pricing__tooltip">
        {{ $t('priceMayVaryWhenWeighed') }}
      </WCToolTip>
      <font-awesome-icon icon="circle-question" />
    </div>
    <div v-if="!hasActualPrice" class="font-weight-bold">
      {{ $t('callForPrice') }}
    </div>
    <div v-if="embeddedPrice" class="font-weight-bold text-primary" data-testid="embeddedPrice">
      {{ $filters.currency(embeddedPrice) }}
    </div>
    <div v-if="price" class="font-weight-bold text-primary" data-testid="price">
      {{ $filters.currency(price) }}
    </div>
  </div>
</template>

<script>
import ProductPricingMixin from '@/mixins/ProductPricingMixin';
import { defineComponent } from 'vue';
import { RIBBON_CONFIG } from '../../constants/PromoRibbonConstants';
import WCToolTip from '../WCToolTip/WCToolTip.vue';

export default defineComponent({
  components: { WCToolTip },
  mixins: [ProductPricingMixin],
  props: {
    item: {
      type: Object,
    },
    price: {
      type: Number,
    },
    embeddedPrice: {
      type: Number,
    },
    isScanMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    acoDiscountToolTipText() {
      let discountText = '';
      if (RIBBON_CONFIG.DISCOUNT_NAME_RIBBON.canDisplay(this.item)) {
        discountText += `${RIBBON_CONFIG.DISCOUNT_NAME_RIBBON.getTextContent(this.item)}\n`;
      }

      if (RIBBON_CONFIG.SALE_AMOUNT_RIBBON.canDisplay(this.item)) {
        discountText += `${RIBBON_CONFIG.SALE_AMOUNT_RIBBON.getTextContent(this.item)}`;
      }
      return discountText;
    },
    hasAcoDiscountToolTip() {
      return this.isScanMode && this.acoDiscountToolTipText;
    },
    avgPerUnitPrice() {
      return this.averagePriceDisplay;
    },
  },
});
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-pricing {
  line-height: normal;

  .wc-pricing__actual--sale {
    color: var(--primary, $primary);
  }

  .wc-pricing__tooltip {
    font-size: $font-size-2xs;
  }
}

.wc-scan-pricing {
  transform: translate3d(0, 0, 0);
}

.whitespace-pre {
  white-space: pre;
}
</style>
