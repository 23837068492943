import { store } from '@/store';

/**
 * Changes the route to include the OT Url Slug if it exists and the current route is by OT ID.
 * @param {*} to destination
 * @param {*} from source
 * @param {*} next next route
 */
export default async function orderTypeAccess(to, from, next) {
  const idOrSlug = to.params.id;
  const orderTypes = store.getters.getConfiguration.orderTypes;
  const orderTypeById = orderTypes?.find(orderType => orderType.id === idOrSlug);

  if (
    orderTypeById &&
    orderTypeById.urlEncodedSlug &&
    orderTypeById.id?.toLowerCase() !== orderTypeById.urlEncodedSlug?.toLowerCase()
  ) {
    next(`/orders/${orderTypeById.urlEncodedSlug}`);
  } else {
    next();
  }
}
